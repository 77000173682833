import "./HeaderAnnouncement.css";
import { MdOutlineMail, MdCall } from "react-icons/md";
import CompanyDetails from "../../_data/CompanyDetails";
function HeaderAnnouncement() {
	return (
		<div className='d-flex flex-column flex-sm-row banner'>
			<span className='mx-3' onClick={() => (window.location = `mailto: ${CompanyDetails.email}`)} style={{ cursor: "pointer" }}>
				<MdOutlineMail className='me-2' />
				{CompanyDetails.email}
			</span>

			<span className='ms-3' onClick={() => (window.location = `tel: +91${CompanyDetails.phone}`)} style={{ cursor: "pointer" }}>
				<MdCall className='me-2' />
				{CompanyDetails.phone}
			</span>
		</div>
	);
}

export default HeaderAnnouncement;
