import { useState, useContext, cloneElement } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Offcanvas, Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import { Button, Dropdown, Select } from "antd";
import { UserOutlined, HistoryOutlined } from "@ant-design/icons";
import { MdOutlineShoppingCart, MdShoppingCart, MdOutlineFavoriteBorder, MdOutlineFavorite } from "react-icons/md";
import { FcGoogle } from "react-icons/fc";
import CartContext from "../../contexts/CartContext";
import FavouriteContext from "../../contexts/FavouriteContext";
import UserContext from "../../contexts/UserContext";
import { Login, Logout } from "../UtilityComponents/Firebase";
import CompanyDetails from "../../_data/CompanyDetails";
import productsJSON from "../../_data/Products.json";
import "../../styles/global.css";
import "./Navbar.css";

const { Option } = Select;

function NavBar(props)
{
  const { cart } = useContext(CartContext);
  const { user, setUser } = useContext(UserContext);
  const { favourite } = useContext(FavouriteContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const onSearchSelect = (value) =>
  {
    navigate("/products/" + value);
  };

  const generateSearchOptions = () =>
  {
    return productsJSON.map((product) => (
      <Option key={product.id} value={product.id}>
        {`${product.name} [${product.id}] (${product.category})`}
      </Option>
    ));
  };

  const handleMenuClick = (e) =>
  {
    switch (e.key)
    {
      case "1":
        // navigate("/prevOrders");
        break;
      case "2":
        Logout(setUser);
        break;
      default:
        break;
    }
  };

  const items = [
    {
      label: user.name,
      key: "0",
      disabled: false,
    },
    {
      label: "View Previous Orders",
      key: "1",
      icon: <HistoryOutlined />,
      disabled: true,
    },
    {
      label: "Logout",
      key: "2",
      icon: <UserOutlined />,
      danger: true,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const NavLink = ({ to, title, children }) => (
    <Link
      to={to}
      className="my-3 mx-2"
      style={{ textDecoration: "none" }}
      title={title}
      onClick={() => setExpanded(false)}
    >
      <span className={`navbar-link ${pathname === to ? "active" : ""}`}>{children}</span>
    </Link>
  );

  const IconButton = ({ to, title, icon, count, color }) =>
  {
    const iconElement = count > 0 ? icon : cloneElement(icon, { className: "me-1 text-secondary" });
    const textColor = count > 0 ? color : "text-secondary";

    return (
      <Link to={to} style={{ textDecoration: "none" }} title={title}>
        <button className="rounded ms-1 px-4 neomorphic-button">
          {iconElement}
          <span className={textColor} style={{ textDecoration: "none" }}>
            {count}
          </span>
        </button>
      </Link>
    );
  };

  const SearchBox = ({ id }) => (
    <Select
      id={id}
      showSearch
      allowClear
      size="large"
      className="w-100"
      placeholder="Search Products"
      onChange={onSearchSelect}
      filterOption={(input, option) =>
        (option?.children ?? "").toLowerCase().includes(input.toLowerCase()) ||
        (option?.children ?? "") === "All Products"
      }
      aria-label="Search Products"
      aria-haspopup="listbox"
      aria-controls={id}
    >
      <Option key={"allProducts"} value={""} style={{ fontWeight: 800 }}>
        All Products
      </Option>
      {generateSearchOptions()}
    </Select>
  );

  return (
    <Navbar
      expand="lg"
      style={{ backgroundColor: "var(--sky-blue)", position: "sticky", top: 0, zIndex: 1 }}
      variant="light"
      collapseOnSelect
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      className="bottom-rounded"
    >
      <Container fluid>
        <Navbar.Brand
          as={Link}
          to="/"
          className="navbar-brand"
          title="Home Page - Sugandha Arts - Metal-based handicrafts Wall Decor, Showpieces and fancy wall clocks"
        >
          <span className="heading brand-name">{CompanyDetails.name}</span>
        </Navbar.Brand>
        <Navbar.Offcanvas
          placement="start"
          style={{ backgroundColor: "var(--sky-blue)", maxWidth: "400px", margin: 0 }}
          id="offcanvasNavbar-expand-lg"
          aria-labelledby="offcanvasNavbarLabel-expand-lg"
        >
          <Offcanvas.Header closeButton className="me-2">
            <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
              <span className="heading brand-name">{CompanyDetails.name}</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav>
              <NavLink to="/" title="Home Page - Sugandha Arts - Metal-based handicrafts Wall Decor, Showpieces and fancy wall clocks">Home</NavLink>
              <NavLink to="/products" title="All Products - Buy Handicraft Wall Decor, Showpieces, Clocks">Products</NavLink>
              <NavLink to="/about" title="About Us">About</NavLink>
              <NavLink to="/faq" title="FAQ">FAQ</NavLink>
              <NavLink to="/contact" title="Contact">Contact</NavLink>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>

        <div className="container-fluid px-1">
          <Row>
            {/* Burger menu */}
            <Col xs={3} sm={2} md={2} className="d-xl-none d-lg-none">
              <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" />
            </Col>

            {/* Searchbox on Desktop */}
            <Col md={6} lg={6} xl={8} className="d-none d-md-block p-0 ps-4 m-0">
              <label htmlFor="search-products" className="visually-hidden">Search Products</label>
              <SearchBox id="search-products" />
            </Col>

            {/* CONTAINER - Fav, Cart, Login  */}
            <Col xs={9} sm={10} md={4} lg={6} xl={4} className="d-flex justify-content-end">
              <IconButton
                to="/favourites"
                title="Favourite Items"
                icon={favourite.length ? <MdOutlineFavorite className="me-1" style={{ color: "var(--pink)" }} /> : <MdOutlineFavoriteBorder className="me-1" />}
                count={favourite.length}
                color="var(--pink)"
              />
              <IconButton
                to="/cart"
                title="Cart Items"
                icon={cart.length ? <MdShoppingCart className="me-1" style={{ color: "var(--navy-blue)" }} /> : <MdOutlineShoppingCart className="me-1" />}
                count={cart.length}
                color="var(--navy-blue)"
              />

              {user.loggedIn ? (
                <Dropdown menu={menuProps}>
                  <Button
                    shape="circle"
                    size="large"
                    className="d-flex align-items-center ms-3"
                    icon={
                      <img
                        src={user.photoURL}
                        onError={(e) => (e.target.src = "/util/floating_whatsapp.png")}
                        alt={user.name}
                        style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                      />
                    }
                  />
                </Dropdown>
              ) : (
                <div id="navbar-signin-btn">
                  <Button onClick={() => Login(setUser)} shape="round" className="ms-2" type="dashed" size="large">
                    <FcGoogle className="me-3" /> Login
                  </Button>
                </div>
              )}
            </Col>

            {/* Searchbox on Mobile */}
            <Col xs={12} sm={12} className="d-xl-none d-lg-none d-md-none d-lg-none mt-3">
              <label htmlFor="search-products-mobile" className="visually-hidden">Search Products</label>
              <SearchBox id="search-products-mobile" />
            </Col>
          </Row>
        </div>
      </Container>
    </Navbar>
  );
}

export default NavBar;